<template>
<v-sheet class="add_intercom_ob ">
  <v-sheet v-if="inputWindow" class="pt_15 bottomNavigationFlex_input ob_add_intercom h_208">
    <v-form class="w100">
      <v-row v-if="!this.goal">
        <v-textarea ref="intercom" background-color="white" filled auto-grow dense outlined v-model="ic_desc" @input="inputIcDescription">
        </v-textarea>
      </v-row>
      <v-row v-if="!this.goal" class="d-flex justify-start ma-0 pa-0">
        <div class="d-flex pa-2 justify-center intercom-btn" @click="fileInput" style="cursor:pointer;">
          <span>
            <v-icon class="btn_2-icons" style="font-size:17px;">mdi-paperclip</v-icon>
            <span>附件</span>
          </span>
          <input type="file" ref="file" style="display:none;" @change="setFileForIntercom" />
        </div>
        <div class="d-flex pa-2 justify-center intercom-btn">
          <v-menu top origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <span class="ma-2">@ 同事</span>
              </span>
            </template>
            <v-card>
              <UserPicker @pick="pickUser" />
            </v-card>
          </v-menu>
        </div>

        <div class="d-flex pa-2 justify-center intercom-btn" @click="allMembers" style="cursor:pointer;">
          <span>
            <span>@ 所有同事</span>
          </span>
        </div>

        <div class=" d-flex justify-center intercom-btn" style="cursor:pointer;">
          <EmojiPicker @inputEmoji="handle_inputEmoji" />
        </div>

        <div class="d-flex pa-0 justify-center intercom-btn">
          <!-- v-model="ic_sms" -->
          <v-checkbox class="sms-check ma-0 pa-0"  label="短信通知" hide-details></v-checkbox>
        </div>

        <div class="d-flex pl-3 justify-center">
          <v-btn color="primary" :disabled="
                                ic_desc.length > 0 || intercomFiles.length > 0
                                    ? false
                                    : true
                            " @click="submitIcDescription">回复</v-btn>
        </div>
      </v-row>
      <v-row>
        <v-container v-if="intercomFiles.length > 0" class="m_0">
          <v-row v-for="(file, index) in intercomFiles" :key="index" class="pa-2 file-item">
            <v-flex shrink>
              <v-icon color="rgb(73, 185, 221)" class="mr-2">
                mdi-file-upload
              </v-icon>
            </v-flex>

            <v-flex d-flex align-center justify-start grow>
              <span style="color:rgb(100, 116, 129);">
                {{ file.file_name.split(".")[0] }}
              </span>
            </v-flex>

            <v-flex shrink>
              <span style="color:rgb(45, 171, 146);margin-right:10px;font-size:12px;">
                文件上传成功
              </span>
            </v-flex>

            <v-flex shrink class="file_remove">
              <v-icon size="15" color="rgb(169, 184, 191)" style="cursor:pointer;" class="file_remove_close" @click="removeFile(file.file_id)">
                mdi-close-circle
              </v-icon>
            </v-flex>
          </v-row>
        </v-container>
      </v-row>
    </v-form>
  </v-sheet>
  <v-sheet v-else class="bottomNavigationFlex width100 ob_add_intercom" @click="displayInputWindow">
    <v-container class="bnBtn width100 ob_intercom_add">
      <v-icon class="mr_5">mdi-pencil</v-icon>
      <span class="bnBtn">需要沟通，请 @ta</span>
    </v-container>
  </v-sheet>
</v-sheet>
</template>

<script>
import UserPicker from '@/components/common/UserPicker.vue';
import EmojiPicker from '@/components/common/EmojiPicker.vue';
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  props: ['object_id', 'tab_index', 'interactiveIcReplyIc'],
  data() {
    return {
      id: "",
      inputWindow: false,
      displayHiddenBtns: false,
      user_name: "",
      enableIcSubmitBtn: false,
      display_files: false,
      file: null,
      ic_desc: "",
      ic_reply_ic: "",
      interactive: false,
      goal:false,     //new
    }
  },
  created() {
    this.id = this.authUser.id;
    let userName = this.authUser.employeeName.split("_");
    userName.forEach((name, index) => {
      this.user_name = name.slice(0, 2).toUpperCase();
    });
    this.interactive = false;
    this.initFiles({
      type: "intercom"
    });
  },
  watch: {
    object_id(v) {
      this.initFiles({
        type: "intercom"
      });
    },
    tab_index(v) {
      if (v == 3) {
        this.inputWindow = true;
        this.displayHiddenBtns = false;
        this.interactive = true;
      } else {
        this.inputWindow = false;
        this.interactive = false;
      }
    },
    interactiveIcReplyIc(v) {
      this.displayHiddenBtns = v.displayReplyWindow;
      this.ic_reply_ic = v.ic_reply_ic;
      this.inputWindow = true;
    }
  },
  components: {
    UserPicker,
    EmojiPicker,
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    ...mapGetters('fileUpload', ['intercomFiles']),
  },
  methods: {
    ...mapActions('intercom', ['getIntercom', 'addIntercom', 'updateIntercom', 'deleteIntercom']),
    ...mapActions('fileUpload', ['initFiles', 'fileUpload', 'deleteFileUploadById']),
    displayInputWindow() {
      this.inputWindow = true;
      this.$emit('openInputWindow');
    },
    hideBtns() {
      this.displayHiddenBtns = false;
    },
    inputIcDescription(e) {
      if (e === "") {
        this.enableIcSubmitBtn = false;
      } else {
        this.enableIcSubmitBtn = true;
      }
    },
    removeFile(id) {
      this.deleteFileUploadById({
        type: "intercom",
        file_id: id
      });
    },
    fileInput() {
      this.$refs.file.click();
    },
    setFileForIntercom(e) {
      this.display_files = true;
      this.file = e.target.files[0];
      var formData = new FormData();
      formData.append("files", this.file);
      this.fileUpload({
        type: "intercom",
        value: formData
      });
    },
    pickUser(param) {
      this.ic_desc += "@[" + param.user.user_post_address + "]";
      this.enableIcSubmitBtn = true;
    },
    allMembers() {
      this.ic_desc += "@[所有同事:all_members]";
    },
    handle_inputEmoji(e) {
      this.ic_desc += e;
      this.$refs.intercom.focus();
    },
    closeInputWindow() {
      this.inputWindow = false;
      this.$emit("closeInputWindow");
    },
    submitIcDescription() {
      let files = [];
      this.intercomFiles.forEach((f) => {
        files.push(f.file_id);
      });
      this.display_files = false;
      this.initFiles({
        type: "intercom"
      });
      if (this.displayHiddenBtns == false) {
        let ic_add = {
          "ic_description": this.ic_desc,
          "ic_parent_type": 0,
          "ic_parent": this.object_id,
          "ic_reply_ic": null,
          "ic_thumbup": null,
          "ic_uploaded_file": files.toString(),
        }
        this.addIntercom(ic_add);
        this.ic_desc = "";
        this.inputIcDescription(this.ic_desc);
      } else if (this.displayHiddenBtns == true) {
        let addReplyIc = {
          "ic_description": this.ic_desc,
          "ic_parent_type": 0,
          "ic_parent": this.object_id,
          "ic_reply_ic": this.ic_reply_ic,
          "ic_thumbup": null,
          "ic_uploaded_file": files.toString(),
        }
        this.addIntercom(addReplyIc);
        this.ic_desc = "";
        this.inputIcDescription(this.ic_desc);
      }
      this.inputWindow = false;
      this.$emit("addNewIntercom");
    },
  }
}
</script>

<style>
.add_intercom_ob {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.ob_intercom_add {
  /* max-width: 1500px !important; */
  width: 100%
}

.bnBtn {
  color: #a4acb9 !important;
  font-size: 14px !important;
}

.mr_5 {
  margin-right: 5px !important;
  color: #a4acb9 !important;
  font-size: 18px !important;
}

.close_intercom_input {
  font-size: 15px !important;
  margin-bottom: 5px;
  margin-right: 5px;
  color: rgb(212 224 224);
}

.close_intercom_input:hover {
  color: rgb(218 45 117);
}

.file_input {
  box-shadow: none;
  background-color: white !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 1px !important;
  font-size: 13px;
  line-height: 20px;
  color: rgb(86, 94, 102);
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, \\5FAE\8F6F\96C5\9ED1, Arial, sans-serif;
}

.file_input:hover {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 2px !important;
}

.file_input .mdi-paperclip {
  font-size: 17px !important;
}

.task_mr_5 {
  margin-right: 5px;
}

.ob_add_intercom {
  background: #fff;
  box-shadow: 0 -2px 8px 0 rgb(127 145 180 / 15%) !important;
  padding: 9px 9px 3px;
  z-index: 11;
}

.show_editBtns_detail {
  height: 26px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.hided_editBtns_detail {
  opacity: 0;
  height: 0px;
}
</style>
